import { render, staticRenderFns } from "./HotGrid.vue?vue&type=template&id=2c8b5d4a&"
import script from "./HotGrid.vue?vue&type=script&lang=js&"
export * from "./HotGrid.vue?vue&type=script&lang=js&"
import style0 from "@/../public/styles/handsontable/handsontable.full.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardTitle,VCheckbox,VCol,VDialog,VDivider,VIcon,VLayout,VMenu,VRow,VSelect,VSheet,VSpacer,VTooltip})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
