<template>
    <v-card style="position: relative;">
        <v-card-title class="light--text"
            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
            style="position: sticky; top: 0; width: 100%; z-index: 1000;"
        >
            <span ref="formTitle" class="headline">Novo item</span>

            <v-spacer></v-spacer>

            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-btn v-on="on"
                        class="pa-0"
                        min-width="48px"
                        text dark
                        @click="closeDialog"
                    >
                        <v-icon color="light">mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Fechar</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row no-gutters class="mt-2">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
            <v-divider class="mx-4"></v-divider>
            <v-card-actions class="px-6 py-6">
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="mr-4"
                    @click="closeDialog"
                >
                    Cancelar
                </v-btn>
                <v-btn color="primary"
                    @click="confirm"
                >
                    Confirmar
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'ZnapFormulaDialog',

    props: {},

    data() {
        return {
           
        }
    },

    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },

        confirm() {
            let payload = { id: 1, name: 'text'}
            this.$emit('insertNewRow', payload)
            this.closeDialog()
        }
    }
}
</script>

<style scoped>
</style>
