<template>
    <v-container class="pa-0 ma-0">
        <div class="d-flex flex-column">
            <span class="text-subtitle-2">Definar célula</span>

            <v-row no-gutters class="d-flex align-center mt-2">
                <v-col cols="4">
                    <span class="text-body-2 mr-4">Coluna: </span>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="solver.column"
                        style="max-width: 36px;"
                        class="text-body-2 centered-input pa-0 ma-0"
                        hide-details
                        readonly
                    />
                </v-col>
            </v-row>

            <v-row no-gutters class="d-flex align-center mt-2">
                <v-col cols="4">
                    <span class="text-body-2 mr-4">Linha: </span>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="solver.rowProps.account_group_description"
                        class="text-body-2 pa-0 ma-0"
                        hide-details
                        readonly
                    />
                </v-col>
            </v-row>

            <v-row no-gutters class="d-flex align-center mt-2">
                <v-col cols="4">
                    <span class="text-body-2 mr-4">Para:</span>
                </v-col>
                <v-col>
                    <v-text-field type="number"
                        v-model.number="solver.targetValue"
                        class="text-body-2 pa-0 ma-0"
                        hint="Escolha o valor a ser atingido"
                        persistent-hint
                    />
                </v-col>
            </v-row>

            <span class="text-subtitle-2 mt-4">Alterando</span>

            <v-row no-gutters class="d-flex align-center mt-2">
                <v-col cols="4">
                    <span class="text-body-2 mr-4">Linha: </span>
                </v-col>
                <v-col>
                    <v-autocomplete
                        v-model="solver.changeRow"
                        :items="rows"
                        item-text="text"
                        item-value="id"
                        class="text-body-2 pa-0 ma-0"
                        hide-details
                        style="z-index: 6000 !important;"
                    />
                </v-col>
            </v-row>

            <v-divider class="mt-6"></v-divider>

            <v-row class="mt-4">
                <v-spacer></v-spacer>
                <v-btn small text class="mr-2 mb-2" color="primary"
                    @click="$emit('close')"
                >
                    Cancelar
                </v-btn>
                <v-btn small class="primary mr-2 mb-2"
                    @click="confirm"
                    :loading="calculating"
                >
                    Calcular
                </v-btn>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'ZnapSolverDialog',

    props: {
        solver: { type: Object, required: true },
        data: { type: Array, required: true }
    },

    data() {
        return {
            rows: [],
            calculating: false
        }
    },

    created() {
        for (let i = 0; i < this.data.length; i++) {
            this.rows.push({ id: i,text: this.data[i].account_group_description })
        }
    },

    methods: {
        confirm() {
            this.calculating = true

            this.$emit('confirm', this.solver)
            this.$emit('close')
            this.calculating = false
        }
    },
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center !important;
}
</style>
